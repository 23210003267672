import React from 'react';
import Layout from 'layout';
import { media } from 'theme/breakpoints';
import Link, { StyledLink } from 'components/ui/link';
import styled from 'styled-components';

const JobList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: 0.75rem 1rem;

    &:hover {
      background-color: #f8f8f8;
      border-radius: 4px;
    }
    h2 {
      font-weight: 800;
      color: #0446ca;
      font-size: 1.3rem;
      ${media.mobile} {
        font-size: 18px;
        line-height: 30px;
      }
    }
    ${StyledLink} {
      color: inherit;
      display: flex;
      ${media.mobile} {
        font-size: 16px;
        line-height: 28px;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const Jobs = () => (
  <Layout
    metaTitle="Careers | Work With Us"
    canonical="/jobs/"
    metaDescription="Work to create world-class session recording software with us. Explore job opportunities at LiveSession."
  >
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 text-left p-4 m-auto">
            <h1 className="mb-5 text-center">Join LiveSession</h1>
            <JobList>
              <li>
                <Link href="/jobs/content-specialist/" className="row">
                  <div className="col-8">
                    <h2>Content Specialist</h2>
                    <span>remote</span>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span
                      className="badge badge-secondary"
                      style={{ padding: '.35rem .75rem', lineHeight: 'inherit' }}
                    >
                      Marketing
                    </span>
                  </div>
                </Link>
              </li>

              {/* <li>
                <Link href="/jobs/junior-react-developer/" className="row">
                  <div className="col-8">
                    <h2>Junior React Developer</h2>
                    <span>remote</span>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span
                      className="badge badge-secondary"
                      style={{ padding: '.35rem .75rem', lineHeight: 'inherit' }}
                    >
                      Engineering
                    </span>
                  </div>
                </Link>
              </li> */}
              {/* <li>
                <Link href="/jobs/go-developer/" className="row">
                  <div className="col-8">
                    <h2>Go Developer</h2>
                    <span>remote</span>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span
                      className="badge badge-secondary"
                      style={{ padding: '.35rem .75rem', lineHeight: 'inherit' }}
                    >
                      Engineering
                    </span>
                  </div>
                </Link>
              </li> */}

              <li>
                <Link href="/jobs/account-executive/" className="row">
                  <div className="col-8">
                    <h2>Account Executive</h2>
                    <span>remote</span>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span
                      className="badge badge-secondary"
                      style={{ padding: '.35rem .75rem', lineHeight: 'inherit' }}
                    >
                      Sales
                    </span>
                  </div>
                </Link>
              </li>
              <li>
                <Link href="/jobs/product-marketing-manager/" className="row">
                  <div className="col-8">
                    <h2>Product Marketing Manager</h2>
                    <span>remote</span>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span
                      className="badge badge-secondary"
                      style={{ padding: '.35rem .75rem', lineHeight: 'inherit' }}
                    >
                      Marketing
                    </span>
                  </div>
                </Link>
              </li>
            </JobList>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default Jobs;
